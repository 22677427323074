import * as React from "react"
import { Link } from 'gatsby';
import { graphql } from "gatsby"

import Seo from "../components/seo"

const NotFoundPage = () => {
	return (
		<>
			<Seo title="404: Not Found" description="We could not find the page you were looking for." />
			<main className="min-h-screen bg-cover bg-top sm:bg-top" style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1485847791529-09ed2263da0b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80")' }}>
				<div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
					<p className="text-sm font-semibold uppercase tracking-wide text-black text-opacity-50">
						404 error
					</p>
					<h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
						Oh! We think you’re lost...
					</h1>
					<p className="mt-2 text-lg font-medium text-black text-opacity-60">
						It looks like the page you’re looking for doesn't exist.
					</p>
					<div className="mt-6">
						<Link to="/" className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-black text-opacity-90 sm:bg-opacity-25 sm:hover:bg-opacity-50">Go back to the blog</Link>
					</div>
				</div>
			</main>
		</>
	)
}

export default NotFoundPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
